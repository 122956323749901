.comment-history
    width: 100%
    height: 150px
    overflow-y: scroll
    background: #fff
    border: 1px solid #E6E6E6
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.2)
    p
        margin-bottom: 10px

.add-comment-btn
    background: #924A91

    display: flex
    justify-content: center
    align-items: center
    p
        color: #fff

.messages-container
    width: 100%
    height: 150px
    overflow-y: scroll
    background: #fff
    border: 1px solid #E6E6E6
    padding: 1rem

.myMessages
    width: 100%
    text-align: right
    display: flex
    align-items: center
    justify-content: flex-end
    button
        word-break: break-word
        max-width: 80%
        text-align: left

.othersMessages
    width: 100%
    text-align: left
    display: flex
    align-items: center
    justify-content: flex-start
    button
        word-break: break-word
        max-width: 80%
        text-align: left

.options
    .MuiGrid-item
        padding: 5px !important
