.app {
  display: grid;
  place-items: center;
  background-color: #dadbd3;
  height: 95vh;
}

.app_body {
  display: flex;
  background-color: #ededed;
  margin-top: 50px;
  height: 90vh;
  width: 90vw;
  box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7);
}

.sidebar {
  display: flex;
  flex-direction: column;
  flex: 0.30;
  min-width: 345px;
}

.sidebar_search {
  display: flex;
  align-items: center;
  background-color: white;
  height: 39px;
  padding: 10px;
}

.sidebar_searchContainer {
  display: flex;
  align-items: center;
  background-color: #e8e3e3;
  width: 100%;
  height: 35px;
  border-radius: 20px;
}

.sidebar_searchContainer > input {
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
  background-color: #e8e3e3;
  outline: none;
}

.sidebar_searchContainer > .MuiSvgIcon-root {
  color: gray;
  /* padding: 10px; */
}

.header_search {
  display: flex;
  align-items: center;
  /* background-color: white;
  height: 39px;
  padding: 10px; */
}

.header_searchContainer {
  display: flex;
  align-items: center;
  background-color: #e8e3e3;
  width: 100%;
  height: 35px;
  border-radius: 20px;
}

.header_searchContainer > input {
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
  background-color: #e8e3e3;
  outline: none;
}

.header_searchContainer > .MuiSvgIcon-root {
  color: gray;
  /* padding: 10px; */
}

.sidebar_header {
  display: flex;
  justify-content: space-between;
  /* padding: 20px 0px; */
  /* border-right: 1px solid lightgray; */
  background-color: white;
}

.sidebar_headerRight {
  display: flex;
  align-items: center;
  justify-content: end;
  /* min-width: 10vw; */
  /* border-right: 1px solid lightgray; */
  background-color: white;
}

.sidebar_headerRight > .MuiSvgIcon-root {
  margin-right: 2vw;
  font-size: 24px !important;
}

.sidebar_chats {
  flex: 1;
  background-color: white;
  overflow: scroll;
}

.sidebarChat {
  display: flex;
  padding: 10px;
  cursor: pointer;
  /* border-bottom: 1px solid #f6f6f6; */
}

/* .sidebarChat:hover {
  background-color: #ebebeb;
} */

.whatsAppCallNamesSingle {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  padding-left: 5%;
}

.whatsAppCallNamesSingle:hover {
  background-color: #ebebeb;
}

.sidebarChat_info > h2 {
  font-size: 16px;
  margin-bottom: 8px;
}

.sidebarChat_info {
  margin-left: 15px;
}

.chat {
  flex: 0.70;
  display: flex;
  flex-direction: column;
  width: 70%;
}

.chat_header {
  padding: 8.2px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
}

.chat_header_upper {
  padding: 0px;
  display: flex;
  background-color: lightgray;
  overflow: auto;
  width: 99.5%;
}

.chat_headerInfo {
  flex: 1;
  padding-left: 20px;
}

.chat_headerInfo > h3 {
  font-size: 18px;
  margin-bottom: 3px;
  font-weight: 500;
}

.chat_headerInfo > p {
  color: gray;
}

.chat_headerRight {
  display: flex;
  justify-content: space-between;
  min-width: 100px;
}

.chat_body {
  flex: 1;
  background-image: url("https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png");
  background-repeat: repeat;
  background-position: center;
  padding: 30px;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}

.chat_message {
  position: relative;
  font-size: 16px;
  padding: 10px;
  border-radius: 10px;
  width: fit-content ;
  background-color: #ffffff;
  margin-bottom: 30px;
}

.chat_name {
  position: absolute;
  top: -15px;
  font-weight: 800;
  font-size: xx-small;
}

.chat_name_comment {
  top: -15px;
  font-weight: 800;
  font-size: xx-small;
}

.chat_timestamp {
  /* margin-left: 10px; */
  font-size: xx-small;
}

.chat_receiver {
  margin-left: auto;
  background-color: #dcf8c6;
}

.chat_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  border-top: 1px solid lightgray;
}

.chat_footer > form {
  flex: 1;
  display: flex;
  justify-content: space-around;
}

.chat_footer > form > input {
  flex: 1;
  border-radius: 30px;
  padding: 10px;
  border: none;
}

.chat_footer > form > button {
  display: none;
}

.chat_footer > .MuiSvgIcon-root {
  color: gray;
  /* padding: 10px; */
  display: flex;
  justify-content: space-between;
  width: 40px;
}

.whatsAppCallTab {
  display: flex;
  width: 100%;
  margin-top: 5%;
  align-items: center;
  justify-content: space-evenly;
  height: 60px;
}

.whatsAppCallTab > div {
  width: 50%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.download_icon > .MuiSvgIcon-root {
  color: gray;
}

.play-button {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: rgba(0,0,0,0.25);
  border-radius: 100%;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  cursor: pointer;
}

.play-button:after {
  content: '';
  display: block;
  position: absolute;
  left: 22px;
  top: 10px;
  border-width: 20px;
  border-style: solid;
  border-color: transparent transparent transparent white;
}

.play-button:hover {
  background-color: #000000;
}

@keyframes blink {  
  0% { color: red; }
  50% { color: blue; }
  100% { color: black; }
}
@-webkit-keyframes blink {
  0% { color: red; }
  50% { color: blue; }
  100% { color: black; }
}
.blink {
  -webkit-animation: blink 1s linear infinite;
  -moz-animation: blink 1s linear infinite;
  animation: blink 1s linear infinite;
}

.toggleChats {
  width: 25%;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  justify-content: center;
}

.specificMessages {
  width: 55%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

/* .specificMessages > span {
  margin-top: -20px;
  margin-left: -10px;
  text-align: left;
  font: normal normal normal 12px/18px "Poppins";
  letter-spacing: 0px;
  color: #9e9e9e;
  opacity: 1;
} */

.specificMessages > p {
  text-align: left;
  font: normal normal normal 14px/21px "Poppins";
  letter-spacing: 0px;
  margin-top: 10px;
  margin-left: -10px;
  opacity: 1;
}

.tab {
  /* overflow: hidden; */
  /* border: 1px solid #ccc; */
  background-color: lightgray;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  background-color: #ccc;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.cross:hover {
  background-color: rgb(157, 150, 150);
}