.modal-form-wrapper
    width: 100%
    display: flex
    place-items: center

.modal-form 
    width: 100%
    padding: 0 10px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .modal-form-fields
        display: flex
        justify-content: space-between
        align-items: center

        div
            width: 316px